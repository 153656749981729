// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import "@fortawesome/fontawesome-free/js/all"
// import "./controllers"
import Bootstrap from "./vendor/bootstrap"
import Favorites from "./app/favorites"

Bootstrap.start()

Favorites.init()
window.Favorites = Favorites

