const Favorites = {
  all: [],
  defaultExpiryInDays: 2000,
  cookieName: "_rpv3_favorites",

  init() {
    document.addEventListener("DOMContentLoaded", () => {
      this.fetchOrCreateFavoritesCookie();
    });
  },

  fetchOrCreateFavoritesCookie() {
    // fetch or create favorites cookie
    const favoritesCookie = this.getCookie(this.cookieName);
    if (favoritesCookie === "") {
      const initialValue = [];
      this.setCookie(this.cookieName, initialValue, this.defaultExpiryInDays);
      this.all = initialValue;
    } else {
      var parsedFavorites = JSON.parse(decodeURIComponent(favoritesCookie));
      this.all = parsedFavorites;
    }
  },

  setFavoriteStatus(element) {
    var listingId = this.getListingIdFromElement(element);

    if (this.all.includes(listingId)) {
      this.setFavorited(element);
    } else {
      this.setUnfavorited(element);
    }
  },

  toggleFavorite(e) {
    var element = e.target;
    var listingId = this.getListingIdFromElement(element);

    if (this.all.includes(listingId)) {
      this.all = this.all.filter((item) => item !== listingId);
    } else {
      this.all.push(listingId);
    }

    var value = encodeURIComponent(JSON.stringify(this.all));
    this.setCookie(this.cookieName, value, this.defaultExpiryInDays);
    this.setFavoriteStatus(element);

    this.updateFavoritesCount();
  },

  updateFavoritesCount() {
    var favoritesIcon = document.getElementById("rp-favorites-badge");
    var favoritesCount = this.all.length;

    if (favoritesCount > 0) {
      favoritesIcon.innerHTML = this.all.length;
      favoritesIcon.hidden = false;
    } else {
      favoritesIcon.innerHTML = 0;
      favoritesIcon.hidden = true;
    }
  },

  getListingIdFromElement(element) {
    return element.getAttribute('data-listing-id');
  },

  getIconFromElement(element) {
    return element.getElementsByClassName("rp-favorite__icon")[0];
  },

  setFavorited(element) {
    var icon = this.getIconFromElement(element);
    icon.classList.add("fas");
    icon.classList.remove("far");
    icon.classList.add("text-secondary");
  },

  setUnfavorited(element) {
    var icon = this.getIconFromElement(element);
    icon.classList.add("far");
    icon.classList.remove("fas");
    icon.classList.remove("text-secondary");
  },

  getCookie(name) {
    var cookies = document.cookie;
    var parts = cookies.split(name + "=");
    var cookieValue = '';
    if (parts.length == 2) {
      cookieValue = parts.pop().split(";").shift();
    }
    return cookieValue;
  },

  setCookie(name, value, expiryInDays) {
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + (expiryInDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + currentDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
}

export default Favorites;
